@font-face {
    font-family: 'Paris2024-Black';
    src: url('../fonts/web/statiques/woff/Paris2024-Black.woff') format('woff');    
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Paris2024-Bold';
    src: url('../fonts/web/statiques/woff/Paris2024-Bold.woff') format('woff');    
    font-weight: bold;
    font-style: normal;
}

*{
    font-family: 'Paris2024-Bold' !important;
    font-size: 24px;    
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Oculta el scroll */
  }

#root{
    background-image: url('../img/64-64-crop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.movil-background {
    background-image: url('../img/Grupo\ 650.png');
    background-size: cover;  /* Ajusta el tamaño de la imagen para cubrir completamente el contenedor */
    background-position: top center;  /* Centra la imagen de fondo */
    width: 100vw;  /* Ajusta el ancho al 100% del ancho de la pantalla */
    height: 100vh; /* Ajusta la altura al 100% de la altura de la pantalla */
    position: relative;
    overflow: auto !important;
}

.movil-img{
    position: absolute;
    width: 223px;  /* Ajusta el ancho proporcionalmente */
    height: 302px; /* Ajusta la altura proporcionalmente */
    transform: translate(-50%, -50%) rotate(-8deg);
    left: 50%;
    top: 320px;   /* Ajusta la posición superior proporcionalmente */
}

.movil-rayas{
    position: absolute;
    width: 52px;   /* Ajusta el ancho proporcionalmente */
    height: 49px;  /* Ajusta la altura proporcionalmente */
    left: calc(50% - 111.5px - 35px - 10px);
    top: 396px;    /* Ajusta la posición superior proporcionalmente */
}

.movil-button{
    position: absolute;
    width: 276px !important;  /* Ajusta el ancho proporcionalmente */
    height: 54px !important;  /* Ajusta la altura proporcionalmente */
    font-size: 16px !important; /* Ajusta el tamaño de fuente proporcionalmente */
    font-weight: bold !important;
    line-height: 24px !important; /* Ajusta la altura de línea proporcionalmente */
    font-style: normal !important;
    text-align: center !important;
    color: #154734 !important;
    left: 50%;     /* Ajusta la posición izquierda proporcionalmente */
    top: 515px;     /* Ajusta la posición superior proporcionalmente */
    transform: translate(-50%, -50%);
    background-image: url('../img/Grupo\ 550.png'); 
    background-size: 100% 100%;
}

.movil-botellas{
    position: absolute;
    width: 353px;   /* Ajusta el ancho proporcionalmente */
    height: 382px;  /* Ajusta la altura proporcionalmente */
    left: 50%;
    top: 675px;
    transform: translate(-50%, -50%);
}

.movil-horizontal-logo{
    position: absolute;
    width: 209px;   /* Ajusta el ancho proporcionalmente */
    height: 74px;   /* Ajusta la altura proporcionalmente */
    left: 101px;    /* Ajusta la posición izquierda proporcionalmente */
    top: 790px;     /* Ajusta la posición superior proporcionalmente */
}


.botellas-img{
    max-width: 380px;
}
  
.first-background{
    background-image: url('../img/Grupo\ 645.png');
}
.other-background{
    background-image: url('../img/Grupo\ 644.png');
    background-position: center top 0px !important;
    background-repeat: no-repeat;
}
.img-left{
    position: absolute;
    top: -396px;
    width: 1054px;
    left: -370px;
}
.img-right{
    position: absolute;
    top: -220px;
    width: 956px;
    right: -350px;
}
.img-botella-left{
    position: absolute;
    top: -45px;
    height: 1528px;;
    left: 0px;
}
.img-botella-right{
    position: absolute;
    top: -25px;
    height: 1478px;
    right: 0px;
}

.mt-0{
    margin-top: 0 !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.camara-img{
   margin-top: -50px !important;
}

.horizontal-logo{
    margin-bottom: 4%;
    width: 443px;
}

.step1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    align-items: center;
}

.container-horizontal-logo{
    margin-bottom: 25px;
}

.h-70vh{
    height: 70vh;    
}

.text-center{
    text-align: center;
}

.button-logo-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;
}

.main-container{
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;    
    width: 1920px !important;
    height: 1080px !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    overflow: hidden;
}

h4.title{
    color: #fff;
    font-size: 30px;
}

h3.title{
    color: #fff;
}

.flex-column{
    flex-direction: column;
}

.union-frutas-container {
    display: flex;
    justify-content: center; /* Centra el contenedor dentro de su contenedor padre */
    align-items: center; /* Centra el contenedor verticalmente si es necesario */
    width: 100%; /* Asegura que el contenedor ocupe el ancho completo de su contenedor padre */
    height: 100%; /* Ajusta según el diseño para cubrir el alto completo o una parte específica */
    position: relative; /* Para que el contenedor sea el contexto de referencia para las imágenes absolutas */
}

.union-frutas{
    display: flex;
    position: relative;
    width: 690px;
}

.union-frutas img{    
    position: absolute;
    width: 269px; 
    height: auto;
    bottom: -80px;
    z-index: 9999;
}

.union-frutas img:nth-child(1) {    
    left: 144px;
    bottom: -90px;
}

.union-frutas img:nth-child(2) {    
    right: 145px;
}


.wizard-container{
    background-repeat: repeat;
    height: 400px;
    max-width: 700px !important;
    border-radius: 400px 400px 0 0;
}

.vertical-horizontal-center{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.vertical-logo{
    position: absolute;
    width: 142px;
    height: 373px;
    z-index: 9999;
    left: 1691px;
    top: 62px;
}

.wizard-button{
    color: #046a38 !important;   
    background-image: url('../img/Grupo\ 550.png'); 
    background-size: 100% 100%;
    width: 687px !important;
    height: 114px;
    top: 35px !important;
    z-index: 9998;
}


.wizard-button:hover, .wizard-button:focus, .wizard-button:active{
    background-color: transparent !important;
}

.radio-selection{
    margin: 5px !important;
    width: 412px !important;
    height: 105px;
    border-radius: 40px !important;
    border: 4px solid #fff !important;
    color: #046a38 !important;
    background: #fff !important;
    margin: 10px !important;
    font-size: 50px !important;
}

.Mui-selected{
    background-color: #046a38 !important;
    color:#fff !important;
}

.image-uploader{
    align-items: center;
    justify-content: end !important;
}

.resultImageContainer{
    width: 100%;
    max-width: 400px;
}

.resultImageContainer img {
    width: 100%;
    height: auto;
    display: block;
}

.qr-container{
    position: absolute;
    width: 729px;
    height: 268px;
    border-radius: 20px;
    background: #ffffff;
    left: 634px;
    top: 422px;
}

.h-80vh{
    height: 80vh;
}

.container-title-green{
    position: absolute;
    bottom: 85px !important;
    left: 600px;
}

.qr-text{
    position: absolute;
    width: 220px;
    height: 210px;
    font-family: "Paris2024";
    font-size: 36px;
    font-weight: bold;
    line-height: 33px;
    font-style: normal;
    text-align: left;
    color: #046a38;
    left: 1100px;
    top: 469px;
}

.qr-canvas canvas{
    position: absolute;
    width: 189px !important;
    height: 189px !important;
    left: 866px;
    top: 468px;
}

.img-qr-flecha{
    position: absolute;
    width: 308.8px;
    height: 307.3px;
    transform: rotate(2deg);
    left: 936px;
    top: 565px;

}

.img-qr-sillas{    
    position: absolute;
    left: 20%;
    width: 30%;
    top: 15%;
    z-index: 1;
}

.img-qr-rayas{    
    position: absolute;
    width: 153.8px;
    height: 149.8px;
    transform: rotate(-17deg);
    left: 98px;
    top: 658px;
}

.img-qr-result {
    position: absolute;
    width: 510px;
    height: 685px;
    transform: rotate(-11deg);
    z-index: 1;
    left: 250px;
    top: 127px;
}

.qr-text-green-bottom{
    position: absolute;
    width: 750px;
    height: 120px;
    font-family: "Paris2024";
    font-size: 55px;
    font-weight: bold;
    line-height: 55px;
    font-style: normal;
    text-align: center;
    color: #046a38;
    left: 598px;
    top: 872px;
}

.img-qr-botellas{
    position: absolute;
    width: 434px;
    height: 447px;
    left: 1367px;
    top: 558px;
}

.title-green{
    color: #046A38;
}

h4.title-green{
    margin: 0 !important;
    text-align: left;
    line-height: 1;
}

h3.title-green{
    font-size: 40px;
    text-align: center;
    z-index: 3;
    position: relative;
    margin: 0 !important;
}

.img-sillas-indicators{
    position: absolute;
    left: 40px;
    width: 717px;
    top: 295px;
    z-index: 1;
}

.img-camera-steps{
    position: absolute;
    right: 535px;
    bottom: 250px;  
}

.img-indicators-steps{
    position: absolute;
    right: 620px;
    top: 550px;
    z-index: 1;
    width: 56px;
}
.preview-overlay{
    width: 640px;
    height: 480px;
    overflow: hidden;
}
.preview-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.image-container {
  width: 200px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: rotate(-15deg);
  margin-top: 30px;
  margin-bottom: 30px;
}

.image-container img {
  width: 100%;
  object-fit: contain; 
}

.image-container img:nth-child(2) {    
  margin-top: 20px;
}

.p-0{
  padding: 0 !important;
}

.qr-page{
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100{
    width: 100% !important;
}

.m-w-100{
    max-width: 100% !important;
}

.button-title{
    font-size: 43px;
}

.text-page3{
    font-size: 60px !important;
    line-height: 55px;
}

.text-title-page3{
    font-size: 44px !important;
}

.text-qr-bottom{
    margin-bottom: -10px !important;
}

.nombre-title{
    font-size: 60px !important;
    margin-top: -80px !important;
    margin-bottom: 40px !important;
}

.radio-group1{
    margin-bottom: 30px;
}

.radio-group2{
    margin-bottom: 40px;
}

.radio-selection {
    margin: 0 15px !important; /* Ajusta este valor según el espacio que desees */
  }

  .main-text{
    font-size: 39px !important;
    line-height: 30px;
  }
  .main-title{
    font-size: 60px !important;
    margin-top: 0;
  }

  .etape-title{
    font-size: 40px;
    margin-top: 15px !important;
    margin-bottom: 10px;
    color: green;
  }

.button-shoot{
    color: #046a38 !important;   
    background-image: url('../img/Grupo\ 550.png'); 
    background-size: 100% 100%;
    width: 400px !important;
    height: 66px;
    top: 15px !important;
    z-index: 9998;
  }

.card {
    border: 17px solid #fff;
    /*border-radius: 10px;*/
    overflow: hidden;
    width: 417px;
    background-color: #fff;
    position: absolute;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999999999;
}

.card-image {
    width: 100%;  /* Ajusta el ancho al 100% del contenedor */
    height: 474px; 
    object-fit: cover;  /* Asegura que la imagen cubra el contenedor sin distorsionarse */
    object-position: center;  /* Centra la imagen dentro del contenedor */
    display: block; /* Elimina cualquier espacio extra causado por el renderizado inline de img */
}


.card-logo-container {
    /*padding: 20px;*/
    text-align: center;
}

.card-logo {
    max-width: 230px;
    height: auto;
}


.card-photo1-left{
    transform: rotate(25deg);
    top: -19%;
    left: -6%;
}

.card-photo2-left{
    transform: rotate(-17deg);
    top: 19%;
    left: 5%;
}

.card-photo3-left{
    transform: rotate(18deg);
    bottom: -4%;
    left: -2%;
}

.card-photo1-right{
    transform: rotate(25deg);
    top: -5%;
    right: -5%;
}

.card-photo2-right{
    transform: rotate(-21deg);
    bottom: -5%;
    right: -1%;
}

.log-container{
    background-color: transparent !important;
    background-image: url('../img/64-64-crop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.log-header, .log-row{
    text-align: center !important;
}

.log-header{
    background-color: #f8b406 !important;
    color: #046a38 !important;    
}
